import axios from 'axios'
import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { getType } from "../utils/type";

export default function Legs() {
    const eid = useParams().eid
    const [legs, setLegs] = useState([])
    const [lastSort, setLastSort] = useState("")
    const [loading, setLoading] = useState(true)
    const [imp, setImport] = useState(false)
    const [files, setFiles] = useState()
    const [wait, setWait] = useState('')
    const [msg, setMsg] = useState('')
    const [err2, setErr2] = useState(<></>)
    const [err, setErr] = useState("")

    const getAll = () => {
        axios.get('/api/leg/event/' + eid)
            .then(r => setLegs(r.data.legs))
            .finally(() => {
                setLoading(false)
                setWait('')
            })
    }

    useEffect(() => {
        getAll()
    }, [])

    const compare = key => {
        if (lastSort !== key) {
            return function (a, b) {
                if (a[key] < b[key]) return -1
                if (a[key] > b[key]) return 1
                return 0
            }
        } else {
            return function (a, b) {
                if (a[key] < b[key]) return 1
                if (a[key] > b[key]) return -1
                return 0
            }
        }
    }

    const sortBy = type => {
        let copy = [...legs]
        copy.sort(compare(type))
        setLegs(copy)
        if (lastSort === type) setLastSort("")
        else setLastSort(type)
    }

    const goTo = id => {
        window.location = "/legs/" + id
    }

    const importFile = (e) => {
        console.log(e)
        setFiles(e[0])
    }

    const runImport = (e) => {
        e.preventDefault()
        if (!files) setErr('Please input a file')
        setWait(' wait')
        const reqConfig = {
            headers: {
                "Content-Type": `multipart/form-data;`
            }
        }
        const data = new FormData()
        data.append('file', files, files.name)
        axios.post("/api/leg/upload", data, reqConfig)
            .then((res) => {
                setMsg('Uploaded')
                //window.location.reload()
            })
            .catch(e => {
                let m = e.response.data.errors.map(er => {
                    const em = er.error ? er.error : er.missingElements.toString()
                    const me = er.missingElements ? ' missing elements' : ''
                    return <p className='Error'>In {er.make} {er.model} found{me}: {em}</p>
                })
                setErr2(m)
                setErr(e.response.data.message)
            })
            .finally(() => {
                getAll()
            })
    }

    return (
        <div className="wide">
            <h1>
                Legs
            </h1>
            {loading &&
                <LinearProgress />
            }
            <table className="eventsTable">
                <tr>
                    <th className="clickable" onClick={() => sortBy("identifier")}>
                        Leg identifier &#8645;
                    </th>
                    <th className="clickable" onClick={() => sortBy("start_time")}>
                        Start time &#8645;
                    </th>
                    <th className="clickable" onClick={() => sortBy("end_time")}>
                        End time &#8645;
                    </th>
                    <th className="clickable" onClick={() => sortBy("date")}>
                        Date &#8645;
                    </th>
                </tr>
                {legs.map((leg) => {
                    return (<tr onClick={() => goTo(leg.id)} key={leg.id}><td>{leg.identifier}</td><td>{leg.start_time}</td><td>{leg.end_time}</td><td>{leg.date}</td></tr>)
                }
                )}

            </table>
            <div className='buttonFieldset'>
                <Link to={"/legs/" + eid + "/new"} className='button submitButton'>Add Leg</Link>
            </div>
            <div className='buttonFieldset'>
                <Link to="/boats/new" className='button submitButton'>Add boats</Link>
                {getType().type === 'admin' &&
                    <button className='button submitButton' onClick={() => setImport(!imp)} >Import</button>
                }
            </div>
            {imp &&
                <div>
                    <div className="fieldset">
                        <label htmlFor='file'>Import file (.xlsx)</label>
                        <input type='file' id='file' accept='.xlsx' onChange={(e) => importFile(e.target.files)} />
                    </div>
                    <div className="buttonFieldset">
                        <button className={'button submitButton' + wait} onClick={(e) => runImport(e)}>
                            {wait ?
                                <CircularProgress color="grey" size="1rem" />
                                : 'Upload'}
                        </button>
                    </div>
                    {msg &&
                        <div>
                            <b>{msg}</b>
                        </div>
                    }
                    {err &&
                        <>
                            <p className="Error">{err}</p>
                            {err2}
                        </>
                    }
                </div>
            }
        </div>
    )

}
