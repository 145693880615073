import uuid from "react-uuid";

export const renderDropdown = (i, value, updateFn) => {
  return (
    <select
      id={i.key}
      value={value}
      onChange={(e) => updateFn(i.key, e.target.value)}
    >
      {i.options.map((t) => {
        return <option value={t}>{t}</option>;
      })}
    </select>
  );
};

export const renderArray = (i, value, updateFn) => {
  const l = value ? value.length : 0;
  const ret = [];
  for (let a = 0; a <= l; a++) {
    let v = "";
    if (value?.[a]) v = value[a];
    if (i.elements.type === "attachment") {
      ret.push(renderAttachmentInput(i, v, updateFn, a));
      continue;
    }
    if (i.elements.type === "boat") {
      continue;
    }
    ret.push(
      <div key={i.index + "atch" + a} style={{ display: "inline-block" }}>
        <label htmlFor={i.key + i.index}>Link</label>
        <input
          type="text"
          id={i.key + i.index}
          value={v}
          onChange={(e) => updateFn(i.key, e.target.value, a)}
        />
      </div>
    );
  }
  return ret;
};

export const renderAttachmentInput = (i, value, updateFn, a) => {
  const file_id = i.index + "file" + a;
  return (
    <div key={file_id} style={{ display: "inline-block" }}>
      <label htmlFor={file_id}>File</label>
      {value && value.location ? (
        <>
          <a href={value.location} target="_blank" rel="noreferrer">
            {value.name}
          </a>
          <button
            className="smallButton deleteButton"
            onClick={(e) => updateFn(i.key, "", a)}
          >
            Remove
          </button>
        </>
      ) : (
        <input
          type="file"
          id={file_id}
          accept="image/*,video/*,.pdf"
          onChange={(e) => updateFn(i.key, e.target.files, a)}
        />
      )}
    </div>
  );
};

export const renderBoatInput = (i, value, updateFn, a) => {
  return (
    <input
      type="text"
      id={uuid()}
      value={value}
      onChange={(e) => updateFn(i.key, e.target.value, a)}
    />
  );
};

export const renderRadio = (i, value, updateFn) => {
  let checkDef = false
  if (!value && i.default) checkDef = true
  return (
    <div className="radioGroup">
      {i.options.map((r) => {
        return (
          <>
            <input
              type="radio"
              name={i.key}
              id={i.key + r}
              value={value}
              onChange={(e) => updateFn(i.key, r)}
              checked={(checkDef && r == i.default) || value == r }
            />
            <span className="radioLabel">{r}</span>
          </>
        );
      })}
    </div>
  );
};

export const renderInputField = (i, value, updateFn) => {
  if (i.type === "dropdown") {
    return renderDropdown(i, value, updateFn);
  }
  if (i.type === "array") {
    return renderArray(i, value, updateFn);
  }
  if (i.type === "file") {
    return <input type="file" id={i.key + i.ind} />;
  }
  if (i.type === "number") {
    return (
      <input
        type="number"
        id={i.key + i.ind}
        value={value}
        {...i.params}
        step=".01"
        onChange={(e) => updateFn(i.key, e.target.value)}
      />
    );
  }
  if (i.type === "date") {
    return (
      <input
        type="date"
        id={i.id}
        value={value}
        onChange={(e) => updateFn(i.key, e.target.value)}
      />
    );
  }
  if (i.type === "time") {
    return (
      <input
        type="time"
        id={i.id}
        value={value}
        onChange={(e) => updateFn(i.key, e.target.value)}
      />
    );
  }
  if (i.type === "radio") {
    return renderRadio(i, value, updateFn);
  }
  if (i.type === "textarea") {
    return (
      <textarea 
        id={i.key + i.ind}
        value={value}
        onChange={(e) => updateFn(i.key, e.target.value)}
        {...i.params}
      />
    )
  }
  return (
    <input
      type="text"
      id={i.key + i.ind}
      value={value}
      onChange={(e) => updateFn(i.key, e.target.value)}
      {...i.params}
    />
  );
};
