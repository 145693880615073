import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import { getType } from "../utils/type";

export default function Boat() {
    const id = useParams().id
    const [boat, setBoat] = useState({})
    const [err, setErr] = useState("")
    const [msg, setMsg] = useState("")
    const [loading, setLoading] = useState(true)
    const linkre = /((https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))/

    useEffect(() => {
        axios.get('/api/boat/' + id)
            .then(r => setBoat(r.data.boat))
            .catch(e => setErr(e.response.data.error))
            .finally(setLoading(false))
    }, [])

    const remove = () => {
        axios.delete('/api/boat/'+id)
            .then(r => {
                setMsg('Deleted')
                setTimeout(() => {
                    window.location = '/boats'
                }, 5000)
            })
            .catch(e => setErr('Something went wrong: ' + e.response.data.error))
    }

    return (
        <div>
            <h1>Boat</h1>
            {loading &&
                <LinearProgress />
            }
            {Object.entries(boat).map(o => {
                let a
                if (o[1] instanceof Array) {
                    a = o[1].map(b => {
                        if (b.location)
                            return <><a href={b.location}>{b.name}</a><br /><label /></>
                        if (b.match(linkre))
                            return <><a href={b}>{b}</a><br /><label /></>
                        return <><span>{b}</span><br /><label /></>
                    })
                }
                if (o[1] instanceof Object && !a) {
                    a = Object.entries(o[1]).map(b => {
                        return <><br /><label><b>{b[0]}</b></label>{b[1]}</>
                    })
                }
                if (!a) a = o[1]
                return (
                    <div className="fieldset" key={o[0]}>
                        <label ><b>{o[0]}:</b></label> {a}
                    </div>
                )
            })}
            {(boat?.owner?.id === getType().id || getType().type === 'admin') &&
                <div className='buttonFieldset'>
                    <Link to={`/boats/${id}/edit`} className='button submitButton' >Edit</Link>
                    <button className='button deleteButton' onClick={remove} >Remove</button>
                </div>
            }
            {msg &&
                <p>{msg}</p>
            }
            {err &&
                <p className="Error">{err}</p>
            }
        </div>
    )
}
