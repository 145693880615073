import axios from "axios";

export const getEvents = async (future) => {
  const events = await axios.get('/api/event')
  let e = events.data.events
  if (future) {
    const now = new Date()
    e = e.filter(ed => {
      return new Date(ed.end_date + 'T23:59:59') >= now
    })
  }
  return e.map(e => {
    return { name: e.name, id: e.id }
  })
}

export const getEvent = async (id) => {
  const event = await axios.get('/api/event/' + id)
  return event.data.event
}
