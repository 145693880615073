export const boatFields = [
  {
    key: "make",
    type: "string",
    label: "Make"
  },
  {
    key: "model",
    type: "string",
    label: "Model"
  },
  {
    key: "length",
    type: "number",
    label: "Length",
    params: {
      min: 0
    }
  },
  {
    key: "beam",
    type: "number",
    label: "Beam",
    params: {
      min: 0
    }
  },
  {
    key: "draft",
    type: "number",
    label: "Draft",
    params: {
      min: 0
    }
  },
  {
    type: "text",
    text: "Please note, that organizer can limit maximum amount of passengers per leg."
  },
  {
    key: "capacity",
    type: "number",
    label: "Capacity",
    params: {
      min: 0
    }
  },
  {
    key: "description",
    type: "string",
    label: "Description"
  },
  {
    key: "attachments",
    type: "array",
    label: "Attachments (images, videos etc.)",
    elements: {
      type: "attachment"
    }
  },
  {
    key: "links",
    type: "array",
    label: "Links (Social medias etc.)",
    elements: {
      type: "text"
    }
  },
  {
    key: "event",
    type: "string",
    label: "Event",
    params: {
      disabled: true,
    },
    automatic: true
  }
]
