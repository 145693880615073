import { useState, useEffect } from 'react'
import axios from 'axios'

import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { getType } from '../utils/type';

export default function Overlay({ legId, close, eventId, orgId, canRegister, refresh, locked }) {
    const [leg, setLeg] = useState({})
    const [loading, setLoading] = useState(true)
    const [wait, setWait] = useState('')
    const [success, setSuccess] = useState(0)

    useEffect(() => {
        let url = '/api/leg/' + legId
        if (getType().type === 'admin' && orgId) url += '/' + orgId
        axios.get(url)
            .then(r => setLeg(r.data.leg))
            .finally(setLoading(false))
        console.log(canRegister)
    }, [legId, success])

    const mapping = {
        make: 'Make',
        model: 'Model',
        length: 'Length (m)',
        beam: 'Beam (m)',
        draft: 'Draft (m)',
        capacity: 'Capacity',
        original_capacity: 'Original capacity',
        description: 'Description',
        reserved: 'Already reserved',
        available: 'Available spots',
        links: 'Links (for social media etc.)',
        attachments: 'Attachments',
        email: 'Contact email',
        business_id: 'Business ID',
        first_name: 'Contact person first name',
        last_name: 'Contact person last name',
        city: 'City',
        phone: 'Phone number',
        postal_number: 'ZIP code',
        organization_name: 'Name',
        address: 'Address'
    }

    const notThese = [
        'id',
        'event',
        'createdAt',
        'updatedAt',
        'registered',
        'type',
        'other_emails',
        'Length (m)',
        'Beam (m)',
        'Draft (m)',
        'Capacity',
        'Original capacity',
        'Description',
        'Already reserved',
        'Available spots',
        'Links (for social media etc.)',
        'Attachments',
        'Contact email',
        'Business ID',
        'Contact person first name',
        'Contact person last name',
        'City',
        'Phone number',
        'ZIP code',
        'Name',
        'Address'
    ]

    const imageTypes = ['jpeg', 'jpg', 'png', 'gif', 'tiff']
    const videoTypes = ['mov', 'mp4', 'avi', 'mpg']

    const register = (id) => {
        setWait(' wait')
        const data = {
            event: eventId,
            leg: legId,
            boat: id
        }
        if (getType().type === 'admin') data.organization = orgId
        axios.post('/api/reg/', data)
            .then(r => {
                setSuccess(success + 1)
                refresh()
            })
            .catch(e => console.info(e))
            .finally(() => {
                setWait('')
                close()
            })
    }

    const removeReg = (id) => {
        axios.delete('/api/reg/' + id)
            .then(r => {
                setSuccess(success + 1)
                refresh()
            })
            .catch(e => console.log(e))
    }

    const renderOwner = v => {
        if (!v[1]) return <></>
        return (
            <div>
                <div className='overlayLine'>
                    <span className='first'>Owner</span>
                    <span className='second'></span>
                </div>
                {Object.entries(v[1]).map(l => {
                    if (notThese.includes(l[0])) return <></>
                    return (
                        <div className='overlayLine' key={'owner' + l}>
                            <span className='first'>{mapping[l[0]]}</span>
                            <span className='second'>{l[1]}</span>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderLinks = (v) => {
        if (v[1].length === 0) return <></>
        return (
            <div>
                <div className='overlayLine'>
                    <span className='first'>Links (for social media etc.)</span>
                    <span className='second'></span>
                </div>
                {v[1].map(l => {
                    if (l[0] === 'links') return renderLinks(l)
                    return (
                        <div className='overlayLine' key={'link' + l}>
                            <span className='first'>Link</span>
                            <span className='second'><a href={l} target='_blank' rel='noreferrer' >{l}</a></span>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderAttachments = (v) => {
        if (v[1].length === 0) return <></>
        return (
            <div>
                <div className='overlayLine'>
                    <span className='first'>Attachments</span>
                    <span className='second'></span>
                </div>
                {v[1].map(a => {
                    let type = a.name.split('.').pop()
                    type = type.toLowerCase()
                    if (type === 'pdf') {
                        return (
                            <div className='overlayLine' key={'link' + a.location}>
                                <span className='first'>Attachment</span>
                                <span className='second'><a href={a.location} target='_blank' rel='noreferrer'>{a.name}</a></span>
                            </div>
                        )
                    }
                    if (imageTypes.includes(type) || a?.mimetype?.split('/')[0] === 'image') {
                        return (
                            <div className='overlayLine' key={'link' + a.location}>
                                <span className='ontop'>Image (<a href={a.location} target='_blank' rel='noreferrer'>Download</a>)</span>
                                <a href={a.location} target='_blank' rel='noreferrer'><img src={a.location} alt={a.name} /></a>
                            </div>
                        )
                    }
                    if (videoTypes.includes(type) || a?.mimetype?.split('/')[0] === 'video') {
                        const vtype = a.mimetype || 'video/' + type
                        return (
                            <div className='overlayLine' key={'link' + a.location}>
                                <span className='ontop'>Video (<a href={a.location} target='_blank' rel='noreferrer'>Download</a>)</span>
                                <video controls>
                                    <source src={a.location} type={vtype} />
                                    Video playback not supported. You can download the video <a href={a.location} target='_blank' rel='noreferrer'>here</a>.
                                </video>
                            </div>
                        )
                    }

                    return (
                        <div className='overlayLine' key={'link' + a.location}>
                            <span className='first'>Attachment</span>
                            <span className='second'><a href={a.location} target='_blank' rel='noreferrer'>{a.name}</a></span>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderBoats = () => {
        if (loading || !leg.boats) return <></>
        return leg.boats.map((b, i) => {
            if (!Object.entries(b)) return <></>
            return (
                <div>
                    <h4>Boat {i + 1}</h4>
                    {Object.entries(b).map(bt => {
                        if (notThese.includes(bt[0])) return <></>
                        if (bt[0] === 'owner') return <></>
                        if (bt[0] === 'links') return <></>
                        if (bt[0] === 'attachments') return <></>
                        if (bt[0] !== 'make' && bt[0] !== 'model' && bt[0] !== 'available') return <></>
                        return (
                            <div className='overlayLine'>
                                <span className='first'>{mapping[bt[0]]}</span>
                                <span className='second'>{bt[1]}</span>
                            </div>
                        )
                    })}
                    {((!leg?.registered && !canRegister && b.available > 0 && !locked) || getType().type === 'admin') &&
                        <div className='overlayLine buttons'>
                            <button className={'button submitButton' + wait} onClick={() => register(b.id)}>
                                {wait ?
                                    <CircularProgress color="grey" size="1rem" />
                                    : 'Register'}
                            </button>
                        </div>
                    }
                    {canRegister &&
                        <div className='overlayLine buttons'>
                            You have already registered to a leg that is at the same time
                        </div>
                    }
                    {b.available === 0 &&
                        <div className='overlayLine buttons'>
                            This boat is full
                        </div>
                    }
                    {locked &&
                        <div className='overlayLine buttons'>
                            Legs have been locked for this day
                        </div>
                    }
                    {((b.registered && !locked) || (b.registered && getType().type === 'admin')) &&
                        <div className='overlayLine buttons'>
                            <button className='button deleteButton' onClick={() => removeReg(b.registered)}>Remove registration</button>
                        </div>
                    }
                </div>
            )
        })
    }

    return (
        <div className="overlayWrapper">
            <div className="overlay">
                {loading &&
                    <LinearProgress />
                }
                <h1>Leg {leg.identifier}</h1>
                <div className="overlayClose" onClick={() => close()}>close &#x2716;</div>

                <div className="overlayInfo">
                    <h3>General information</h3>
                    <div className='overlayLine'>
                        <span className='first'>Starting point</span>
                        <span className='second'>{leg.starting_point}</span>
                    </div>
                    <div className='overlayLine'>
                        <span className='first'>Ending point</span>
                        <span className='second'>{leg.ending_point}</span>
                    </div>
                    <div className='overlayLine'>
                        <span className='first'>Via</span>
                        <span className='second'>{leg.via}</span>
                    </div>
                    <div className='overlayLine'>
                        <span className='first'>Date</span>
                        <span className='second'>{leg.date}</span>
                    </div>
                    <div className='overlayLine'>
                        <span className='first'>Time</span>
                        <span className='second'>{leg.start_time} - {leg.end_time}</span>
                    </div>
                    <div className='overlayLine'>
                        <span className='first'>Description</span>
                        <span className='second'>{leg.description}</span>
                    </div>
                    <h3>Boats</h3>
                    {renderBoats()}
                </div>
            </div>
        </div>
    )
}
