import { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Link } from "react-router-dom";

export default function Leg() {
    const [leg, setLeg] = useState({})
    const [err, setErr] = useState("")
    const [msg, setMsg] = useState("")
    const [legs, setLegs] = useState([])
    const [copyLeg, setCopyLeg] = useState("")
    const id = useParams().id

    useEffect(() => {
        axios.get('/api/leg/' + id)
            .then(r => {
                setLeg(r.data.leg)
            })
            .catch(e => {
                console.error(e)
                setErr(e.response.data.error)
            })
    }, [])

    useEffect(() => {
        if (!leg.event) return
        axios.get('/api/leg/event/' + leg.event.id)
            .then(r => {
                setLegs(r.data.legs)
            })
            .catch(e => {
                console.error(e)
                setErr(e.response.data.error)
            })
    }, [leg])

    const copy = () => {
        axios.post('/api/leg/copy', { id })
            .then(r => {
                setMsg("Leg copied")
            })
            .catch(e => {
                console.error(e)
                setErr(e.response.data.error)
            })
    }

    const copyBoats = () => {
        if (!copyLeg) {
            setErr("Select a leg")
            return
        }
        const toNew = {
            id: copyLeg,
            boats: leg.boats.map(b => b.id),
            append: true
        }

        console.log(toNew)

        axios.post('/api/leg/edit', toNew)
            .then(r => {
                setMsg("Boats copied")
            })
            .catch(e => {
                console.error(e)
                setErr(e.response.data.error)
            })
    }

    const remove = () => {
        const eid = leg.event.id
        axios.delete('/api/leg/' + id)
            .then(r => {
                console.log("Deleted")
                window.location = '/events/' + eid
            })
            .catch(e => {
                console.log(e.response.data)
                setErr(e.response.data)
            })
    }

    return (
        <div className="narrow">
            <h1>Leg</h1>
            {Object.entries(leg).map(o => {
                let a
                if (o[0] === 'boats') {
                    a = o[1].map(b => {
                        return (
                            <>
                                <span>{b.make} {b.model}</span><br /><label />
                            </>
                        )
                    })
                }
                if (o[1] instanceof Array && !a) {
                    a = o[1].map(b => {
                        return <><span>{b}</span><br /><label /></>
                    })
                }
                if (o[1] instanceof Object && !a) {
                    a = Object.entries(o[1]).map(b => {
                        return <><br /><label><b>{b[0]}</b></label>{b[1]}</>
                    })
                }
                if (!a) a = o[1]
                return (
                    <div className="fieldset" key={o[0]}>
                        <label ><b>{o[0]}:</b></label> {a}
                    </div>
                )
            })}
            <div className='buttonFieldset'>
                <button className='button deleteButton' onClick={remove} >Remove</button>
                <Link to={`/legs/${id}/edit`} className='button submitButton' >Edit</Link>
                <button className='button submitButton' onClick={copy} >Copy</button>
            </div>
            <div>
                <h3>Copy boats to a leg</h3>
                <p>Boats will be appended to the list of boats on target leg.</p>
                <div className="fieldset">
                    <label htmlFor="legselect">Leg:</label>
                    <select id="legselect" onChange={(e) => setCopyLeg(e.target.value)}>
                        {legs.map(l => {
                            return (
                                <option key={l.id} value={l.id}>{l.identifier}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='buttonFieldset'>
                    <button className='button submitButton' onClick={copyBoats} >Copy boats</button>
                </div>
            </div>
            {msg &&
                <p>{msg}</p>
            }
            {err &&
                <p className="Error">{err}</p>
            }
        </div>
    )
}
