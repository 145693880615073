import axios from 'axios'
import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'

export default function Event() {
    const id = useParams().id
    const [event, setEvent] = useState({})
    const [err, setErr] = useState("")

    useEffect(() => {
        axios.get('/api/event/' + id)
            .then(r => setEvent(r.data.event))
            .catch(e => setErr(e.response.data.message))
    }, [])

    const copy = () => {
        axios.post('/api/event/copy', { id })
            .then(r => {
                const new_id = r.data.id
                window.location.href = '/events' + new_id
            })
            .catch(e => setErr(e.response.data))
    }

    return (
        <div>
            <h1>Event {event?.name}</h1>
            {Object.entries(event).map(o => {
                return (
                    <div className="fieldset">
                        <label ><b>{o[0]}:</b></label> {o[1]}
                    </div>
                )
            })}
            <div className='buttonFieldset'>
                <button className='button submitButton' onClick={copy}>Copy</button>
                <Link to={`/events/${id}/edit`} className='button submitButton' >Edit</Link>
                <Link to={`/legs/${id}/new`} className='button submitButton' >Add legs</Link>
                <Link to={`/legs/event/${id}`} className='button submitButton' >List legs</Link>
            </div>
            {err &&
                <p className="Error">{err}</p>
            }
        </div>
    )
}
