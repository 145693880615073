import { useParams } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { organizationFields } from "./fields";
import CircularProgress from "@mui/material/CircularProgress";
import { renderRadio } from "../utils/Inputs";
import { getType } from "../utils/type";

export default function EditOrganization() {
  const id = useParams().id || null;

  const [org, setOrg] = useState({});
  const [err, setErr] = useState("");
  const [msg, setMsg] = useState("");
  const [wait, setWait] = useState("");
  const [wmsg, setWmsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (!id) {
      axios
        .get("/api/settings")
        .then((r) => {
          setWmsg(r.data.settings.welcome_text);
        })
        .catch((e) => {
          console.error(e);
          setErr(e.response.data.error);
        });
      return;
    }
    axios
      .get("/api/org/" + id)
      .then((r) => {
        setOrg(r.data.organization);
      })
      .catch((e) => {
        console.error(e);
        setErr(e.response.data.error);
      });
  }, []);

  useEffect(() => {
    console.log(org);
  }, [org]);

  const submit = (e) => {
    e.preventDefault();
    if (!confirmed && !id) {
      setShowModal(true); // Prompt user for verification
      return;
    }
    setWait(" wait");
    const o = { ...org };
    if (o._id) {
      o.id = o._id;
      delete o._id;
    }
    if (o.__v) delete o.__v;
    let url = "/api/org/edit";
    let successm = "Information updated succesfully";
    if (!id) {
      url = "/api/org/register";
      successm = "Registration sent";
    }
    axios
      .post(url, o)
      .then((r) => {
        console.log(r);
        setWait("");
        setMsg(successm);
      })
      .catch((e) => {
        setWait("");
        console.error(e)
        setErr(e.response.data.error);
      });
  };

  const handleConfirmation = () => {
    setConfirmed(true);
    setShowModal(false);
    setWait(" wait");
    const o = { ...org };
    const url = "/api/org/register";
    const successm = "Registration sent";
    axios
      .post(url, o)
      .then((r) => {
        console.log(r);
        setWait("");
        if (r.data.update) {
          setMsg("We found that you have already registered. We have sent you an email, which contains a link where you have to confirm the changes you have made.")
        } else {
          setMsg(successm);
        }
      })
      .catch((e) => {
        setWait("");
        console.log(e)
        setErr(e.response.data.error);
      });
  };

  const update = (key, value) => {
    const o = { ...org };
    o[key] = value;
    setOrg(o);
  };

  const updateArray = (key, value, ind) => {
    console.log(key, ind, value);
    const o = { ...org };
    if (!o[key]) o[key] = [];
    if (!o[key][ind]) o[key].push("");
    if (value === "") {
      console.log(o[key], value, ind);
      o[key].splice(ind, 1);
      console.log(o[key]);
    } else {
      o[key][ind] = value;
    }
    setOrg(o);
  };

  const renderDropdown = (i, value) => {
    return (
      <select
        id={i.key}
        value={value}
        onChange={(e) => update(i.key, e.target.value)}
      >
        {i.options.map((t) => {
          return <option value={t}>{t}</option>;
        })}
      </select>
    );
  };

  const renderArray = (i, value) => {
    const l = value ? value.length : 0;
    const ret = [];
    for (let a = 0; a <= l; a++) {
      let v = "";
      if (value?.[a]) v = value[a];
      if (i.elements.type === "email") {
        ret.push(
          <input
            type="email"
            id={i.key}
            value={v}
            onChange={(e) => updateArray(i.key, e.target.value, a)}
          />
        );
        continue;
      }
      ret.push(
        <input
          type="text"
          id={i.key}
          value={v}
          onChange={(e) => updateArray(i.key, e.target.value, a)}
        />
      );
    }
    return ret;
  };

  const renderInputField = (i, value) => {
    const t = org.type || "test driver";
    const ot = getType()?.type
    if (i.for !== t && i.for !== "all" && ot !== "admin") {
      return <></>;
    }
    if (ot !== "admin" && i.adminOnly) return <></>
    if (i.type === "dropdown") {
      return renderDropdown(i, value);
    }
    if (i.type === "array") {
      return renderArray(i, value);
    }
    if (i.type === "email") {
      return (
        <input
          type="email"
          id={i.key}
          value={value}
          onChange={(e) => update(i.key, e.target.value)}
          required={i.required}
        />
      );
    }
    if (i.type === "radio") {
      return renderRadio(i, value, update);
    }
    if (i.if) {
      let r = org[i.if];
      if (r === "yes") {
        return (
          <input
            type="text"
            id={i.key}
            value={value}
            onChange={(e) => update(i.key, e.target.value)}
            required={i.required}
          />
        );
      }
      return <></>;
    }
    return (
      <input
        type="text"
        id={i.key}
        value={value}
        onChange={(e) => update(i.key, e.target.value)}
        required={i.required}
      />
    );
  };
  const t = org.type || "test driver";
  const updateText = !id ? "Submit" : "Update";
  const ot = getType()?.type
  return (
    <div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Confirm</h2>
            {Object.entries(org).map((o) => {
              let txt = o[1];
              if (typeof txt === "boolean") txt = txt ? "Yes" : "No";
              if (o[0] === "_id" || o[0] === "__v") return <></>;
              let lbl = organizationFields?.find(e => e.key === o[0])?.label;
              return (
                <div className="fieldset">
                  <label>
                    <b>{lbl}:</b>
                  </label>{" "}
                  <span className='modalText'>{txt}</span>
                </div>
              );
            })}
            <p>Are you sure you want to submit the data?</p>
            <div className="modal-buttons">
              <button onClick={handleConfirmation} className="button submitButton">Yes</button>
              <button onClick={() => setShowModal(false)} className='button deleteButton'>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {!id ? (
        <>
          <h1>Registration Form for invited journalists</h1>
          <p style={{whiteSpace: "pre-wrap"}}>{wmsg}</p>
        </>
      ) : (
        <h1>Edit organization</h1>
      )}
      <form onSubmit={submit}>
        {organizationFields.map((f, k) => {
          if (f.for !== t && f.for !== "all" && t !== "admin") {
            return <></>;
          }
          if (f.if && org[f.if] !== "yes") {
            return <></>;
          }
          if (f.adminOnly && ot != "admin") {
            return <></>;
          }
          if (f.type === "title") {
            return (
              <div className="fieldset" key={k}>
                <h2>{f.text}</h2>
              </div>)
          }
          return (
            <div className="fieldset" key={k}>
              <label htmlFor={f.key}>{f.label}{f.required ? "*" : ""}</label>
              {renderInputField(f, org[f.key])}
            </div>
          );
        })}
        <div className="buttonFieldset">
          <button type="submit" className={"button submitButton" + wait}>
            {wait ? <CircularProgress color="grey" size="1rem" /> : updateText}
          </button>
        </div>
      </form>
      {msg && (
        <div>
          <b>{msg}</b>
        </div>
      )}
      {err && <p className="Error">{err}</p>}
    </div>
  );
}
