import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';

export default function Events() {
    const [events, setEvents] = useState([])
    const [lastSort, setLastSort] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get('/api/event').then(r => setEvents(r.data.events)).catch(e => console.error(e))
            .finally(setLoading(false))
    }, [])

    const compare = key => {
        if (lastSort !== key) {
            return function (a, b) {
                if (a[key] < b[key]) return -1
                if (a[key] > b[key]) return 1
                return 0
            }
        } else {
            return function (a, b) {
                if (a[key] < b[key]) return 1
                if (a[key] > b[key]) return -1
                return 0
            }
        }
    }

    const sortBy = type => {
        let copy = [...events]
        copy.sort(compare(type))
        setEvents(copy)
        if (lastSort === type) setLastSort("")
        else setLastSort(type)
    }

    const goTo = id => {
        window.location = "/events/" + id
    }

    return (
        <div className="wide">
            <h1>
                Events
            </h1>
            {loading &&
                <LinearProgress />
            }
            <table className="eventsTable">
                <tr>
                    <th className="clickable" onClick={() => sortBy("name")}>
                        Event name &#8645;
                    </th>
                    <th className="clickable" onClick={() => sortBy("location")}>
                        Location &#8645;
                    </th>
                    <th className="clickable" onClick={() => sortBy("start_date")}>
                        Start date &#8645;
                    </th>
                    <th className="clickable" onClick={() => sortBy("end_date")}>
                        End date &#8645;
                    </th>
                </tr>
                {events.map((event) => {
                    return (<tr onClick={() => goTo(event.id)} key={event.id}><td>{event.name}</td><td>{event.location}</td><td>{event.start_date}</td><td>{event.end_date}</td></tr>)
                }
                )}

            </table>
            <div className='buttonFieldset'>
                <Link to="/events/new" className='button submitButton'>New event</Link>
            </div>
        </div>
    )
}
