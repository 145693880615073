import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { getType } from "../utils/type";

export default function Boats() {
    const [boats, setBoats] = useState([])
    const [lastSort, setLastSort] = useState("")
    const [loading, setLoading] = useState(true)
    const [err, setErr] = useState("")
    const [imp, setImport] = useState(false)
    const [files, setFiles] = useState()
    const [wait, setWait] = useState('')
    const [msg, setMsg] = useState('')
    const [err2, setErr2] = useState(<></>)
    const [filtered, setFiltered] = useState(true)
    const [fboats, setFBoats] = useState([])

    const getAll = () => {
        setLoading(true)
        axios.get('/api/boat')
            .then(r => {
                setFBoats(r.data.boats)
                if (getType().type === 'manufacturer') {
                    const bts = r.data.boats.filter(b => b?.owner?.id === getType().id)
                    setBoats(bts)
                } else {
                    setBoats(r.data.boats)
                }
            })
            .catch(e => setErr(e.response.data))
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getAll()
    }, [])

    const compare = key => {
        if (lastSort !== key) {
            return function (a, b) {
                if (a[key] < b[key]) return -1
                if (a[key] > b[key]) return 1
                return 0
            }
        } else {
            return function (a, b) {
                if (a[key] < b[key]) return 1
                if (a[key] > b[key]) return -1
                return 0
            }
        }
    }

    const sortBy = type => {
        let copy = [...boats]
        copy.sort(compare(type))
        setBoats(copy)
        if (lastSort === type) setLastSort("")
        else setLastSort(type)
    }

    const goTo = id => {
        window.location = "/boats/" + id
    }

    const importFile = (e) => {
        console.log(e)
        setFiles(e[0])
    }

    const runImport = (e) => {
        e.preventDefault()
        if (!files) setErr('Please input a file')
        setWait(' wait')
        const reqConfig = {
            headers: {
                "Content-Type": `multipart/form-data;`
            }
        }
        const data = new FormData()
        data.append('file', files, files.name)
        axios.post("/api/boat/upload", data, reqConfig)
            .then((res) => {
                setMsg('Uploaded')
                //window.location.reload()
            })
            .catch(e => {
                let m = e.response.data.errors.map(er => {
                    const em = er.error ? er.error : er.missingElements.toString()
                    const me = er.missingElements ? ' missing elements' : ''
                    return <p className='Error'>In {er.make} {er.model} found{me}: {em}</p>
                })
                setErr2(m)
                setErr(e.response.data.message)
            })
            .finally(() => {
                getAll()
                setWait('')
            })
    }

    const filterOwn = () => {
        if (filtered) {
            setFiltered(false)
            setBoats([...fboats])
            return
        }
        setFiltered(true)
        const myId = getType().id
        const bts = fboats.filter(b => b?.owner?.id === myId)
        setBoats(bts)
    }

    return (
        <div className="wide">
            <h1>
                Boats
            </h1>
            {loading &&
                <LinearProgress />
            }
            <div className="buttonFieldset">
                {getType().type === 'manufacturer' &&
                    <button className="button submitButton" onClick={filterOwn}>{filtered ? 'View all boats' : 'View my boats'}</button>
                }
            </div>
            <div className="tableWrapper">
                <table className="eventsTable">
                    <tr>
                        <th className="clickable" onClick={() => sortBy("model")}>
                            Model &#8645;
                        </th>
                        <th className="clickable" onClick={() => sortBy("make")}>
                            Make &#8645;
                        </th>
                        <th className="clickable" onClick={() => sortBy("owner.organization_name")}>
                            Manufacturer &#8645;
                        </th>
                        <th className="clickable" onClick={() => sortBy("event.name")}>
                            Event &#8645;
                        </th>
                    </tr>
                    {boats.map((boat) => {
                        return (<tr onClick={() => goTo(boat.id)} key={boat.id}><td>{boat.model}</td><td>{boat.make}</td><td>{boat?.owner?.organization_name || boat?.owner?.email}</td><td>{boat.event.name}</td></tr>)
                    }
                    )}

                </table>
            </div>
            <div className='buttonFieldset'>
                <Link to="/boats/new" className='button submitButton'>Add boats</Link>
                {getType().type === 'admin' &&
                    <button className='button submitButton' onClick={() => setImport(!imp)} >Import</button>
                }
            </div>
            {imp &&
                <div>
                    <div className="fieldset">
                        <label htmlFor='file'>Import file (.xlsx)</label>
                        <input type='file' id='file' accept='.xlsx' onChange={(e) => importFile(e.target.files)} />
                    </div>
                    <div className="buttonFieldset">
                        <button className={'button submitButton' + wait} onClick={(e) => runImport(e)}>
                            {wait ?
                                <CircularProgress color="grey" size="1rem" />
                                : 'Upload'}
                        </button>
                    </div>
                    {msg &&
                        <div>
                            <b>{msg}</b>
                        </div>
                    }
                    {err &&
                        <>
                            <p className="Error">{err}</p>
                            {err2}
                        </>
                    }
                </div>
            }
        </div>
    )
}
