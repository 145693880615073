import logo from './Flotari_logo.png'
import {useState, useEffect} from 'react'
import axios from 'axios'
import Loggedin from './Loggedin'

export default function Index() {
    const [wtext, setWtext] = useState("Loading")

    useEffect(() => {
        axios
            .get("/api/settings")
            .then((r) => {
                setWtext(r.data.settings.front_page_text);
            })
            .catch((e) => {
                console.error(e);
            });
    }, [])

    if (localStorage.getItem('token')) {
        return (
            <div>
                <Loggedin />
            </div>
        )
    }
    return (
        <div>
            <img src={logo} alt="Logo" style={{ width: '200px', marginTop: '20px' }} />

            <h4 style={{whiteSpace: "pre-wrap"}}>{wtext}</h4>

        </div>
    )
}
