import { useState, useEffect } from 'react'
import axios from 'axios'

const types = [
    "admin",
    "manufacturer",
    "test driver"
]

export default function AddOrganization() {
    const [email, setEmail] = useState("")
    const [type, setType] = useState("test driver")
    const [msg, setMsg] = useState("")
    const [err, setErr] = useState("")

    const emailRe = /[\w-\._]+@([\w-]+\.)+[\w-]+/;

    useEffect(() => {
        console.log(email, type)
    }, [email, type])

    const create = (e) => {
        e.preventDefault()
        const found = email.match(emailRe)
        if (!found) {
            setErr("Not an email")
            return
        }
        axios.post('/api/org/new', { orgs: [{ email, type }] })
            .then(res => {
                console.log(res)
                setMsg("Organization created")
            })
            .catch(e => {
                console.error(e)
                setErr(e.response.data.error)
            })
    }

    return (
        <div>
            <form onSubmit={create}>
                <h1>Add organization</h1>
                <div className='fieldset'>
                    <label htmlFor="email">Login email address</label>
                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className='fieldset' >
                    <label htmlFor="type">Type of the organization</label>
                    <select id="type" value={type} onChange={(e) => setType(e.target.value)}>
                        {types.map(t => {
                            return (
                                <option value={t}>{t}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='buttonFieldset'>
                    <input type='submit' className='button submitButton' value='Create' />
                </div>
            </form>
            {msg &&
                <div>
                    <b>{msg}</b>
                </div>
            }
            {err &&
                <p className="Error">{err}</p>
            }
        </div>
    )
}
