import axios from "axios"

export const getBoats = async (event) => {
  const b = await axios.get('/api/boat/')
  return b.data.boats
}

export const getBoatsFromLeg = async (event, st, et, dt, lid) => {
  const b = await axios.get('/api/boat/')
  const legs = await axios.get('/api/leg/event/' + event)
  const bts = b.data.boats.filter(a => a.event.id === event)
  console.log(legs.data.legs)
  const overlap = legs.data.legs.filter(({ start_time, end_time, date, id }) => {
    if (id === lid) return false
    const start = new Date(date + 'T' + start_time + ':00.000+03:00')
    const stt = new Date(dt + 'T' + st + ':00.000+03:00')
    const end = new Date(date + 'T' + end_time + ':59.999+03:00')
    const ett = new Date(dt + 'T' + et + ':59.999+03:00')
    console.log(start, stt, end)
    if (start < stt < end || start < ett < end) return true
    return false
  })
  const reserved = []
  overlap.forEach(bt => {
    bt.boats.forEach(a => reserved.push(a))
  });
  console.log(reserved, overlap, bts)
  return bts.filter(a => !reserved.includes(a.id))

}
