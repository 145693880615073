export const organizationFields = [
  {
    type: "title",
    text: "JOURNALIST INFORMATION",
    for: "test driver"
  },
  {
    key: "first_name",
    type: "string",
    label: "First name (as it is written in the passport)",
    for: "test driver",
    required: true
  },
  {
    key: "last_name",
    type: "string",
    label: "Last name (as it is written in the passport)",
    for: "test driver",
    required: true
  },
  {
    key: "first_name",
    type: "string",
    label: "First name",
    for: "manufacturer",
    required: true
  },
  {
    key: "last_name",
    type: "string",
    label: "Last name",
    for: "manufacturer",
    required: true
  },
  {
    key: "organization_name",
    type: "string",
    label: "Publication/media",
    for: "test driver",
    required: true
  },
  {
    key: "website",
    type: "string",
    label: "Publication/media website",
    for: "test driver",
    required: true
  },
  {
    key: "email",
    type: "email",
    label: "Main contact email (will be used to send travel/event updates)",
    for: "test driver",
    required: true
  },
  {
    key: "email",
    type: "email",
    label: "Main contact email",
    for: "manufacturer",
    required: true
  },
  {
    key: "other_emails",
    type: "array",
    label: "Other emails",
    elements: {
      type: "email",
    },
    for: "manufacturer",
    required: false
  },
  {
    key: "phone",
    type: "string",
    label: "Phone number (will be used to send travel/event updates)",
    for: "test driver",
    required: true
  },
  {
    key: "phone",
    type: "string",
    label: "Phone number",
    for: "manufacturer",
    required: true
  },
  {
    key: "departure_airport",
    type: "string",
    label: "Desired departure airport (incoming flight)",
    for: "test driver",
    required: true
  },
  {
    key: "arrival_airport",
    type: "string",
    label: "Desired arrival airport (returning flight)",
    for: "test driver",
    required: true
  },
  {
    key: "tshirt",
    type: "string",
    label: "T-shirt size (please use XS-XXXXL)",
    for: "test driver",
    required: true
  },
  {
    type: "title",
    text: "ADDITIONAL INFORMATION",
    for: "test driver"
  },
  {
    key: "day_before",
    type: "radio", // ! radio
    options: ["yes", "no"],
    label: "Will arrive the day before the event (applies to participants arriving from outside the EU)",
    for: "test driver",
    required: false,
    default: "no"
  },
  {
    key: "hotel_before",
    type: "radio", // ! radio
    options: ["yes", "no"],
    label: "Airport hotel accomodation needed",
    for: "test driver",
    if: "day_before",
    required: false
  },
  {
    key: "day_after",
    type: "radio", // ! radio
    options: ["yes", "no"],
    label: "Will depart the day after the event (applies to participants arriving from outside the EU)",
    for: "test driver",
    required: false,
    default: "no"
  },
  {
    key: "hotel_after",
    type: "radio", // ! radio
    options: ["yes", "no"],
    label: "Airport hotel accomodation needed",
    for: "test driver",
    if: "day_after",
    required: false
  },
  {
    // ! OPTIONAL
    key: "allergies",
    type: "string",
    label: "Food allergies and/or dietary restrictions",
    for: "test driver",
    required: false
  },
  {
    // ! OPTIONAL
    key: "additional_travel_info",
    type: "string",
    label: "Additional travel plan information",
    for: "test driver",
    required: false
  },
  {
    // ! OPTIONAL
    key: "additional_info",
    type: "string",
    label: "Additional information for the organizer",
    for: "test driver",
    required: false
  },
  {
    key: "organization_name",
    type: "string",
    label: "Organization name",
    for: "manufacturer",
    required: true
  },
  {
    key: "organization_show_name",
    type: "string",
    label: "Name of the organization to show",
    for: "manufacturer",
    required: true
  },
  {
    key: "business_id",
    type: "string",
    label: "Business ID",
    for: "manufacturer",
    required: true
  },
  {
    key: "address",
    type: "string",
    label: "Address",
    for: "manufacturer",
    required: true
  },
  {
    key: "postal_number",
    type: "string",
    label: "Postal number",
    for: "manufacturer",
    required: true
  },
  {
    key: "city",
    type: "string",
    label: "City",
    for: "manufacturer",
    required: true
  },
  {
    key: "type",
    type: "dropdown",
    options: ["admin", "test driver", "manufacturer"],
    label: "Type",
    adminOnly: true,
    for: "all",
    required: true
  },
  {
    key: "social_media",
    type: "array",
    label: "Social medias",
    elements: {
      type: "string",
    },
    for: "manufacturer",
    required: false
  }
];
