import uuid from 'react-uuid'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

const fields = {
    name: {
        type: 'string',
        label: 'Name of the event'
    },
    location: {
        type: 'string',
        label: 'General location of the event'
    },
    start_date: {
        type: 'date',
        label: 'Start date'
    },
    end_date: {
        type: 'date',
        label: 'End date'
    },
    description: {
        type: 'textbox',
        label: 'Description'
    },
    program: {
        type: 'textbox',
        label: 'Event program'
    }
}

export default function NewEvent() {
    const id = useParams().id
    // Initialize values
    const vs = {}
    Object.entries(fields).forEach(k => {
        vs[k[0]] = ''
    })

    const [eventData, setEventData] = useState(vs)
    const [msg, setMsg] = useState("")
    const [err, setErr] = useState("")
    const [wait, setWait] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        console.log(eventData)
    }, [eventData])

    useEffect(() => {
        if (id) {
            axios.get('/api/event/' + id)
                .then(r => setEventData(r.data.event))
                .catch(e => setErr(e.response.data.message))
                .finally(setLoading(false))
        } else {
            setLoading(false)
        }
    }, [])

    const submit = (e) => {
        e.preventDefault();
        console.log(eventData)
        setWait(" wait")
        const o = { ...eventData }
        let url
        if (id) {
            url = "/api/event/edit"
            if (o._id) {
                o.id = o._id
                delete o._id
            }
            if (o.__v) delete o.__v
        } else {
            url = "/api/event"
        }
        axios.post(url, o)
            .then(r => {
                const m = id ? "Event updated" : "Event created"
                setMsg(m)
                setWait('')
            })
            .catch(e => {
                console.error(e)
                setErr(e.response.data.message)
                setWait('')
            })
    }

    const updateEventData = (k, v) => {
        let o = eventData
        o[v] = k
        setEventData({ ...o })
    }

    const renderInputField = (type, id, value, onChange, target) => {
        switch (type) {
            case 'string':
                return <input type='text' id={id} value={value} onChange={(e) => onChange(e.target.value, target)} />
            case 'date':
                return <input type='date' id={id} value={value} onChange={(e) => onChange(e.target.value, target)} />
            case 'textbox':
                return <textarea id={id} value={value} onChange={(e) => onChange(e.target.value, target)} />
            default:
                return <input id={id} onChange={(e) => onChange(e.target.value, target)} />
        }
    }

    const renderInputs = () => {
        return Object.entries(fields).map((k, v) => {
            const id = uuid()
            return (
                <div className="fieldset" key={v} >
                    <label htmlFor={id} >{k[1]?.label}</label>
                    {renderInputField(k[1].type, id, eventData[k[0]], updateEventData, k[0])}
                </div>
            )
        })
    }

    renderInputs()

    return (<div>
        <h1>Create a new event</h1>
        {loading &&
            <LinearProgress />
        }
        <form onSubmit={submit}>
            {renderInputs()}
            <div className='buttonFieldset'>
                <button type='submit' className={'button submitButton' + wait}>
                    {wait ?
                        <CircularProgress color="grey" size="1rem" />
                        : id ? 'Edit' : 'Create'}
                </button>
            </div>
        </form>
        {msg &&
            <div className='buttonFieldset'>
                <b>{msg}</b>
            </div>
        }
        {err &&
            <p className="Error">{err}</p>
        }
    </div>)
}
