export const getType = () => {
  const token = localStorage.getItem('token')
  if (!token) return null
  const mid = token.split('.')[1]
  const encoded = atob(mid)
  const json = JSON.parse(encoded)
  if (!json) {
    return { id: null, type: null }
  }
  return json
}
