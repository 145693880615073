import { useState, useEffect } from 'react'
import axios from 'axios'
import { boatFields } from './fields'
import { renderInputField } from '../utils/Inputs'
import CircularProgress from '@mui/material/CircularProgress';
import { getEvents } from '../utils/events';
import { getType } from '../utils/type';
import { getOrganizations } from '../utils/organizations';
import { useParams } from 'react-router-dom';

export default function NewBoat() {
    const id = useParams().id
    const [boats, setBoats] = useState([{}])
    const [err, setErr] = useState("")
    const [msg, setMsg] = useState("")
    const [wait, setWait] = useState('')
    const [event, setEvent] = useState("")
    const [events, setEvents] = useState([])
    const [errs, setErrs] = useState([])
    const [errI, setErrI] = useState()
    const [orgs, setOrgs] = useState([])
    const [org, setOrg] = useState(getType().type === 'manufacturer' ? getType().id : "")

    const errStyle = {
        color: 'red',
    }

    useEffect(() => {
        console.log(boats)
    }, [boats])

    useEffect(() => {
        if (id) {
            axios.get('/api/boat/' + id).then(r => {
                const o = r.data.boat
                o.event = o.event.id
                o.owner = o.owner.id
                setBoats([o])
            })
        }
        getEvents(true).then(e => {
            setEvents(e)
            if (!id) setEvent(e[0].id)
        })
        const t = getType()
        if (t.type === 'admin' && !id) {
            getOrganizations().then(o => {
                console.log(o)
                setOrgs(o.data)
                setOrg(t.id)
            })
        }
    }, [])

    useEffect(() => {
        const b = boats.map(b => {
            return { ...b, event }
        })
        setBoats(b)
    }, [event])

    useEffect(() => {
        const b = boats.map(b => {
            return { ...b, owner: org }
        })
        setBoats(b)
    }, [org])

    const onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
            console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
        }
    };

    const submit = async (e) => {
        e.preventDefault()
        // setWait(' wait')
        let url = '/api/boat/'
        if (id) url += '/edit'
        let content = { boats }
        if (id) content = { ...boats[0] }
        const reqConfig = {
            headers: {
                "Content-Type": `multipart/form-data;`
            }
        }
        setMsg('Uploading files')
        setWait(' wait')
        if (content.boats instanceof Array) {
            let i = 1
            for (const c of content.boats) {
                setMsg('Uploading file no.' + i)
                i++
                if (!c.attachments) continue
                const data = new FormData()
                for (const a of c.attachments) {
                    console.log(a)
                    data.append('file', a[0], a[0].name)
                }
                if (c.attachments.length < 1) continue
                try {
                    const r = await axios.post("/api/boat/upload-images", data, reqConfig, onUploadProgress)
                    const locations = r.data.locations
                    c.attachments = locations
                    console.log(c)
                } catch (e) {
                    setErr('Error occured while uploading images: ', e)
                }
            }
        }
        console.log(content)
        if (content.attachments && content.attachments.length > 0) {
            const data = new FormData()
            for (const a of content.attachments) {
                console.log(a)
                if (a.location) continue
                data.append('file', a[0], a[0].name)
            }
            const r = await axios.post("/api/boat/upload-images", data, reqConfig)
            const locations = r.data.locations
            const urls = content.attachments.filter(a => a.location)
            locations.forEach(l => urls.push(l))
            content.attachments = urls
            console.log(content)
        }
        setMsg('')
        axios.post(url, { ...content })
            .then(r => {
                const m = "Saved"
                setMsg(m)
                setWait('')
            })
            .catch(e => {
                console.error(e)
                let m = e.response.data.message || e.response.data.error
                if (e.response.status === 400 && e.response.data.missingElements) {
                    const i = e.response.data.i + 1
                    m += ": " + e.response.data.missingElements.toString() + " on boat: " + i
                    setErrs(e.response.data.missingElements)
                    setErrI(e.response.data.i)
                }
                setErr(m)
                setWait('')
            })
    }

    const addBoat = (e) => {
        e.preventDefault()
        const a = [...boats]
        const o = { event }
        if (org) o.owner = org
        a.push({ ...o })
        setBoats(a)
    }

    const removeBoat = (e, ind) => {
        e.preventDefault()
        const a = [...boats]
        a.splice(ind, 1)
        setBoats(a)
    }

    const handleKey = e => {
        if (e.keyCode === 13) e.preventDefault()
    }

    const renderBoat = (ind) => {
        const updateBoat = (key, value, i) => {
            const b = [...boats]
            if (typeof i === "number") {
                if (!b[ind][key]) b[ind][key] = []
                b[ind][key][i] = value
                if (value === "" || value.length === 0) {
                    b[ind][key].splice(i, 1)
                }
            } else {
                b[ind][key] = value
            }
            if (errs.includes(key)) {
                const e = [...errs]
                delete e[e.indexOf(key)]
                setErrs(e)
                console.log(e)
            }
            setBoats(b)
        }
        return (
            <>
                <h3>Boat {ind + 1}</h3>
                {boatFields.map((f, k) => {
                    const title = f?.params?.disabled ? "You can not modify this value" : f.label
                    const error = errI === ind && errs.includes(f.key) ? " error" : ""
                    if (f.type === "text") {
                        return (
                          <div className="fieldset" key={k}>
                            <p>{f.text}</p>
                          </div>)
                      }
                    return (
                        <div className={'fieldset' + error} key={k}>
                            <label htmlFor={f.key + ind} title={title} style={errI === ind && errs.includes(f.key) ? errStyle : {}}>{f.label}</label>
                            {renderInputField({ ...f, index: ind }, boats[ind][f.key], updateBoat)}
                        </div>
                    )
                })}
                {!id &&
                    <div className='fieldset'>
                        <div style={{ textAlign: 'right' }}>
                            {boats.length > 1 &&
                                <button className='button deleteButton' onClick={(e) => removeBoat(e, ind)}>Remove</button>
                            }
                            <button className='button submitButton' onClick={addBoat}>Add another</button>
                        </div>
                    </div>
                }
            </>
        )
    }

    return (
        <div>
            <h1>Add Boats</h1>
            <form onSubmit={submit} onKeyDown={handleKey}>
                <div className='fieldset'>
                    <label htmlFor="event_selection">Select event</label>
                    <select onChange={(e) => setEvent(e.target.value)} value={event}>
                        {events.map(e => {
                            return (
                                <option key={e.id} value={e.id}>{e.name}</option>
                            )
                        })}
                    </select>
                </div>
                {orgs.length > 0 &&
                    <div className='fieldset'>
                        <label htmlFor="event_selection">Select organization</label>
                        <select onChange={(e) => setOrg(e.target.value)} value={org}>
                            {orgs.map(o => {
                                return (
                                    <option key={o.id} value={o.id}>{o.organization_name || o.email}</option>
                                )
                            })}
                        </select>
                    </div>
                }
                {boats.map((b, k) => {
                    return renderBoat(k)
                })}
                <div></div>
                <div className='buttonFieldset'>
                    <button type='submit' className={'button submitButton' + wait}>
                        {wait ?
                            <CircularProgress color="grey" size="1rem" />
                            : 'Submit'}
                    </button>
                </div>
            </form>
            {msg &&
                <div className='buttonFieldset'>
                    <b>{msg}</b>
                </div>
            }
            {err &&
                <p className="Error">{err}</p>
            }
        </div>
    )
}
