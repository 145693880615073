import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { getType } from "../utils/type";

export default function Organization() {
  const [org, setOrg] = useState({});
  const [err, setErr] = useState("");
  const [msg, setMsg] = useState("");
  const id = useParams().id;

  useEffect(() => {
    axios
      .get("/api/org/" + id)
      .then((r) => {
        setOrg(r.data.organization);
      })
      .catch((e) => {
        console.error(e);
        setErr(e.response.data.error);
      });
  }, []);

  const remove = (e) => {
    axios
      .delete("/api/org/" + id)
      .then((r) => {
        console.log("Deleted");
        if (getType().id === id) {
          setMsg(
            "Your organization has been removed. You have been logged out automatically and will be redirected to home page in 5 seconds."
          );
          localStorage.removeItem("token");
          setTimeout(() => {
            window.location = "/";
          }, 5000);
        }
        setMsg(
          "Organization have been deleted. You will be redirected to organization list in 5 seconds."
        );
        setTimeout(() => {
          window.location = "/organizations";
        }, 5000);
      })
      .catch((e) => {
        console.log(e.response.data);
        setErr(e.response.data.error);
      });
  };

  const confirm = () => {
    axios
      .post("/api/org/confirm", { id })
      .then((r) => {
        console.log("Confirmed");
        setMsg("Confirmed");
      })
      .catch((e) => {
        console.log(e.response.data);
        setErr(e.response.data.error);
      });
  };

  return (
    <div className="narrow">
      <h1>Organization</h1>
      {Object.entries(org).map((o) => {
        let txt = o[1];
        if (typeof txt === "boolean") txt = txt ? "Yes" : "No";
        if (o[0] === "_id" || o[0] === "__v") return <></>;
        return (
          <div className="fieldset">
            <label>
              <b>{o[0]}:</b>
            </label>{" "}
            {txt}
          </div>
        );
      })}
      <div className="buttonFieldset">
        <Link to={`/organizations/${id}/edit`} className="button submitButton">
          Edit
        </Link>
        <button className="button deleteButton" onClick={(e) => remove()}>
          Remove
        </button>
        {getType().type === "admin" && !org.confirmed && (
          <button className="button submitButton" onClick={confirm}>
            Confirm
          </button>
        )}
      </div>
      {msg && (
        <div>
          <b>{msg}</b>
        </div>
      )}
      {err && <p className="Error">{err}</p>}
    </div>
  );
}
