import { useEffect, useState } from "react";
import axios from 'axios'
import { getEvents } from "./utils/events";

export default function Loggedin() {
    const [event, setEvent] = useState()
    const [registrations, setRegistrations] = useState([])
    const [pastRegs, setPastRegs] = useState([])

    const buildDate = (time, date) => {
        console.log(date, time)
        const d = new Date(date + "T" + time)
        return d
    }

    useEffect(() => {
        getEvents(true).then(r => {
            setEvent(r[0].id)
        })
        .catch(e => {
            console.error(e)
        })
    }, [])

    useEffect(() => {
        if (!event) return
        axios.get("/api/reg/event/" + event)
            .then(r => {
                const now = Date.now()
                const regs = r.data.registrations.sort((a,b) => 
                    buildDate(a.leg.start_time, a.leg.date) - buildDate(b.leg.start_time, b.leg.date)
                )
                const pastR = regs.filter(reg => buildDate(reg.leg.start_time, reg.leg.date) < now)
                const futureRegs = regs.filter(reg => buildDate(reg.leg.start_time, reg.leg.date) >= now)
                setRegistrations(futureRegs)
                setPastRegs(pastR)
                console.log(futureRegs)
            })
            .catch(e => {
                console.error(e)
            })
    }, [event])

    return (
        <div>
            <h3>Upcoming trips</h3>
            {registrations.map((r, k) => {
                return (
                    <div key={k}>
                        <h4>Leg: {r.leg.identifier}, {r.leg.date} {r.leg.start_time} - {r.leg.end_time}</h4>
                        <p>Boat: {r.boat.make}, {r.boat.model}</p>
                    </div>
                )
            })}
            <h3>Past trips</h3>
            {pastRegs.map((r, k) => {
                return (
                    <div key={k*121}>
                        <h4>Leg: {r.leg.identifier}, {r.leg.date} {r.leg.start_time} - {r.leg.end_time}</h4>
                        <p>Boat: {r.boat.make}, {r.boat.model}</p>
                    </div>
                )
            })}
        </div>
    )
}
