export const settingsFields = [
  {
    key: "welcome_text",
    type: "textarea",
    label: "Text for registration page",
  },
  {
    key: "registration_email",
    type: "textarea",
    label: "Email to be sent after registration",
  },
  {
    key: "confirmation_email",
    type: "textarea",
    label: "Email to be sent after registration confirmed",
  },
  {
    key: "front_page_text",
    type: "textarea",
    label: "Front page text",
  },
];
