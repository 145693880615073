import { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { getEvent } from '../utils/events'
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Overlay from './Overlay';
import { getType } from '../utils/type';
import { getOrganizations } from '../utils/organizations';

export default function CalView() {
    const eid = useParams().eid
    const [legs, setLegs] = useState([])
    const [event, setEvent] = useState({})
    const [dayLegs, setDayLegs] = useState([])
    const [day, setDay] = useState("")
    const [selectedDay, setSelectedDay] = useState("")
    const [loading, setLoading] = useState(true)
    const [firstStart, setFirstStart] = useState(6)
    const [lastEnd, setLastEnd] = useState(20)
    const [show, setShow] = useState('')
    const [orgs, setOrgs] = useState([])
    const [org, setOrg] = useState(getType().id)
    const [msg, setMsg] = useState('')
    const [err, setErr] = useState('')
    const [wait, setWait] = useState('')
    const [all, setAll] = useState(true)

    useEffect(() => {
        axios.get('/api/leg/event/' + eid)
            .then(r => {
                setLegs(r.data.legs)
            })
        getEvent(eid).then(r => setEvent(r))

        if (getType().type === 'admin') {
            getOrganizations().then(o => {
                setOrgs(o.data)
                setOrg(getType().id)
            })
        }
    }, [])

    useEffect(() => {
        if (getType().type !== 'admin' || !org) return
        axios.get('/api/leg/event/' + eid + '/' + org)
            .then(r => {
                setLegs(r.data.legs)
            })
    }, [org, eid])

    useEffect(() => {
        const today = new Date()
        if (selectedDay) {
            const s = new Date(selectedDay)
            setDay(s)
        } else {
            const start = new Date(event.start_date)
            if (start > today) {
                setDay(start)
            } else {
                setDay(today)
            }
        }
    }, [selectedDay, event, eid])

    useEffect(() => {
        if (event.name && legs.length > 0) {
            setLoading(false)
        }

    }, [event, legs])

    useEffect(() => {
        if (!day || legs.length < 1) return
        const dl = []
        let smallest = 6
        let biggest = 20
        for (const l of legs) {
            const d = new Date(l.date)
            if (d.toDateString() === day.toDateString()) {
                dl.push(l)
                const s = parseInt(l.start_time.split(':')[0])
                const e = parseInt(l.end_time.split(':')[0])
                if (s < smallest) smallest = s
                if (e > biggest) biggest = e
            }
        }
        setFirstStart(smallest)
        setLastEnd(biggest)
        setDayLegs(dl)
        const a = dl.map(l => {
            const ol = getOverlapObjects(l.id)
            const r = ol.map(o => o.registered)
            return r.includes(true)
        })
        setAll(!a.includes(false))
    }, [day, legs, eid])

    const refresh = () => {
        axios.get('/api/leg/event/' + eid)
            .then(r => {
                setLegs(r.data.legs)
            })
        getEvent(eid).then(r => setEvent(r))

        if (getType().type === 'admin') {
            getOrganizations().then(o => {
                setOrgs(o.data)
                setOrg(getType().id)
            })
        }
    }

    const lock = () => {
        setWait(' wait')
        const lock_state = !dayLegs[0].locked
        console.log(lock_state)
        axios.post('/api/leg/lock', { event: eid, date: selectedDay, lock_state })
            .then(r => setMsg('Legs have been locked'))
            .catch(e => setErr('Error ockured: ' + e.response.data.message))
            .finally(setWait(''))
    }

    const deleteAll = () => {
        setWait(' wait')
        // Selected organization: org
        axios.post('/api/reg/deletall', { event: eid, organization: org })
            .then(r => {
                setMsg('Registrations removed')
                refresh()
            })
            .catch(e => setErr('Error ockured: ' + e.response.data.message))
            .finally(setWait(''))
    }

    const getOverlapObjects = (legId) => {
        if (!legId) return []
        const l = legs.find(a => a.id === legId)
        const ett = new Date(l.date + 'T' + l.end_time + ':00+03:00')
        const stt = new Date(l.date + 'T' + l.start_time + ':01+03:00')
        const ol = legs.filter(a => {
            if (a.id === legId) return true
            const st = new Date(a.date + 'T' + a.start_time + ':01+03:00')
            const et = new Date(a.date + 'T' + a.end_time + ':00+03:00')
            return (st <= stt && stt <= et) || (st <= ett && ett <= et) || (stt <= st && st <= ett) || (stt <= et && et <= ett)
        })
        return ol
    }

    const getOverlaps = (legId) => {
        if (!legId) return []
        const l = dayLegs.find(a => a.id === legId)
        const ett = new Date(l.date + 'T' + l.end_time + ':00+03:00')
        const stt = new Date(l.date + 'T' + l.start_time + ':01+03:00')
        const ol = dayLegs.filter(a => {
            if (a.id === legId) return true
            const st = new Date(a.date + 'T' + a.start_time + ':01+03:00')
            const et = new Date(a.date + 'T' + a.end_time + ':00+03:00')
            return (st <= stt && stt <= et) || (st <= ett && ett <= et) || (stt <= st && st <= ett) || (stt <= et && et <= ett)
        })
        return ol.map(a => a.id)
    }

    const renderTimes = () => {
        const r = []
        for (let i = firstStart; i <= lastEnd; i++) {
            r.push(
                <div className='timespan' key={'cal' + i}>
                    <span className='timespanTime'>{i}:00</span>
                </div>
            )
        }
        return r
    }

    const renderLegs = () => {
        const r = []
        for (const l of dayLegs) {
            const viaText = l.via ? ' - ' + l.via : ''
            const st = new Date(l.date + 'T' + l.start_time + ':01+03:00')
            const et = new Date(l.date + 'T' + l.end_time + ':00+03:00')
            const first = new Date(l.date + 'T00:00:00+03:00').setHours(firstStart)

            const t = (st.getTime() - first) / 60000
            const h = (et.getTime() - st.getTime()) / 60000
            let c = '#2222ff'
            if (l.registered) c = '#23a75d' // If registered to this leg
            if (!l.registered && l.total_capacity === l.registrations) c = '#ff2231' // If leg full

            const ol = getOverlaps(l.id)
            let w = (100 / ol.length) - 0.4
            let wp = 0
            let f = false
            ol.forEach(o => {
                if (o !== l.id && !f) {
                    wp += w + 0.4
                } else {
                    f = true
                }
            })
            const registered = dayLegs.filter(a => {
                return ol.includes(a.id) && !a.registered
            })
            let d = ''
            let dis = false
            let lock = l.locked
            let bstring = `${l.boats.length} boat${l.boats.length === 1 ? '' : 's'}`
            if (registered.map(r => r.id).includes(l.id) && !l.registered && registered.length !== ol.length) {
                c = '#888'
                d = ' disabled'
                dis = true
            }
            if (l.locked && !l.registered) {
                c = '#888'
                d = ' disabled'
            }
            const style = {
                height: h + 'px',
                top: t + 'px',
                backgroundColor: c,
                left: wp + '%',
                width: w + '%'
            }
            if (l.registered && l.registered_to) {
                bstring = `Registered to: ${l.registered_to.make} ${l.registered_to.model}`
            }
            r.push(
                <div className={"calEvent" + d} style={style} key={l.id} onClick={() => setShow({ id: l.id, disabled: dis, locked: lock })} >
                    <span className='calEventText'><b>{l.identifier}</b> {l.starting_point}{viaText} - {l.ending_point}</span><br />
                    <span className='calEventTime'>{l.start_time} - {l.end_time} </span>
                    <span className='calEventText'>{bstring}</span> <br />
                </div>
            )
        }
        return r
    }

    return (
        <div className="narrow">
            <h1>Register</h1>
            {loading &&
                <LinearProgress />
            }
            {!all &&
                <p className="Error">Please register to all legs for this day</p>
            }
            {orgs.length > 0 &&
                <div className='fieldset'>
                    <label htmlFor="event_selection">Select organization</label>
                    <select onChange={(e) => setOrg(e.target.value)} value={org}>
                        {orgs.filter(a => a.type === "test driver").sort((a,b) => (''+a.organization_name).localeCompare(b.organization_name)).map(o => {
                            return (
                                <option key={o.id} value={o.id}>{o.organization_name || o.email} {o.first_name}</option>
                            )
                        })}
                    </select>
                </div>
            }
            {!loading &&
                <div className='fieldset'>
                    <label htmlFor='dateInput'>Day</label>
                    <input
                        type='date'
                        id='dateInput'
                        min={event.start_date}
                        max={event.end_date}
                        value={selectedDay}
                        onChange={
                            (e) => setSelectedDay(e.target.value)
                        } />
                </div>
            }
            <div className='cal'>
                {renderTimes()}
                <div className='calEvents'>
                    {renderLegs()}
                </div>
            </div>
            {getType().type === 'admin' &&
                <div className='buttonFieldset'>
                    <button onClick={lock} className={'button submitButton' + wait}>
                        {wait ?
                            <CircularProgress color="grey" size="1rem" />
                            : '(Un)Lock day'}
                    </button>
                    <button onClick={deleteAll} className={'button submitButton' + wait}>
                        {wait ?
                            <CircularProgress color="grey" size="1rem" />
                            : 'Delete all bookings'}
                    </button>
                </div>
            }

            {msg &&
                <div className='buttonFieldset'>
                    <b>{msg}</b>
                </div>
            }
            {err &&
                <p className="Error">{err}</p>
            }
            {show &&
                <Overlay legId={show.id} close={() => setShow('')} eventId={eid} orgId={org} canRegister={show.disabled} refresh={refresh} locked={show.locked} />
            }
        </div>
    )

}
