import { useRouteError } from "react-router-dom";
import Nav from './components/Nav'

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <>
        <Nav login={false} config={null} />
        <div id="error-page" style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}} >
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
            <i>{error.statusText || error.message}</i>
        </p>
        </div>
    </>
  );
}
