import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Root from "./routes/root";
import reportWebVitals from "./reportWebVitals";
import {
  createHashRouter,
  createBrowserRouter,
  RouterProvider,
  useParams,
} from "react-router-dom";
import axios from "axios";
import ErrorPage from "./error";
import Login from "./components/Login";
import Index from "./components/Index";
import NewEvent from "./components/events/NewEvent";
import Organizations from "./components/organizations/Organizations";
import AddOrganization from "./components/organizations/AddOrganization";
import Organization from "./components/organizations/Organization";
import EditOrganization from "./components/organizations/Edit";
import Events from "./components/events/Events";
import Event from "./components/events/Event";
import NewBoat from "./components/boats/NewBoat";
import Boat from "./components/boats/Boat";
import Boats from "./components/boats/Boats";
import AddLeg from "./components/legs/Add";
import Legs from "./components/legs/Legs";
import Leg from "./components/legs/Leg";
import Download from "./components/downloads/Download";
import CalView from "./components/registration/View";
import SelectEvent from "./components/registration/SelectEvent";
import { getType } from "./components/utils/type";
import Settings from "./components/settings/Settings";

if (window.location.href.indexOf("localhost") > 0)
  axios.defaults.baseURL = "http://localhost:3001";

let login = false;
// if (localStorage.getItem("token")) login = true;

if (localStorage.getItem("token")) {
  const exp = getType().exp
  const d = new Date(0)
  d.setUTCSeconds(exp)
  console.log(d)
  if (d < new Date()) {
    localStorage.removeItem("token")
  } else {
    login = true
  }
}

if (login) {
  axios.defaults.headers.common["authorization"] =
    "bearer " + localStorage.getItem("token");
}

if (login && localStorage.getItem("redirect") !== null) {
  const path = localStorage.getItem("redirect");
  localStorage.removeItem("redirect");
  window.location.pathname = path;
}

const Loginfn = () => {
  localStorage.setItem("token", useParams().token);
  window.location.pathname = "/";
  return <></>;
};

const Regedit = () => {
  axios.get(`/api/org/regedit/${useParams().token}/${useParams().data}`)
    .then(window.location.pathname = "/")
  // window.location.pathname = "/"
  return <></>
}

let children = [];
if (!login) {
  children = [
    {
      path: "",
      element: <Index />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "login/:token",
      element: <Loginfn />,
    },
    {
      path: "register",
      element: <EditOrganization />,
    },
    {
      path: "regedit/:token/:data",
      element: <Regedit />,
    },
  ];
} else {
  children = [
    {
      path: "",
      element: <Index />,
    },
    {
      path: "events/new",
      element: <NewEvent />,
    },
    {
      path: "organizations",
      element: <Organizations />,
    },
    {
      path: "organizations/add",
      element: <AddOrganization />,
    },
    {
      path: "organizations/:id",
      element: <Organization />,
    },
    {
      path: "organizations/:id/edit",
      element: <EditOrganization />,
    },
    {
      path: "events",
      element: <Events />,
    },
    {
      path: "events/:id",
      element: <Event />,
    },
    {
      path: "events/:id/edit",
      element: <NewEvent />,
    },
    {
      path: "boats/new",
      element: <NewBoat />,
    },
    {
      path: "boats/:id",
      element: <Boat />,
    },
    {
      path: "boats/",
      element: <Boats />,
    },
    {
      path: "boats/:id/edit",
      element: <NewBoat />,
    },
    {
      path: "legs/:eid/new",
      element: <AddLeg />,
    },
    {
      path: "legs/event/:eid",
      element: <Legs />,
    },
    {
      path: "legs/:id",
      element: <Leg />,
    },
    {
      path: "legs/:id/edit",
      element: <AddLeg />,
    },
    {
      path: "downloads",
      element: <Download />,
    },
    {
      path: "register/:eid",
      element: <CalView />,
    },
    {
      path: "register",
      element: <SelectEvent />,
    },
    {
      path: "settings",
      element: <Settings />,
    },
  ];
}

if (getType()?.type === "tester") {
  children[0] = {
    path: "",
    element: <SelectEvent />,
  };
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
