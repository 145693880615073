export const legFields = [
  {
    key: "starting_point",
    type: "string",
    label: "Starting location"
  },
  {
    key: "ending_point",
    type: "string",
    label: "Ending location"
  },
  {
    key: "date",
    type: "date",
    label: "Date"
  },
  {
    key: "start_time",
    type: "time",
    label: "Start time"
  },
  {
    key: "end_time",
    type: "time",
    label: "End time"
  },
  {
    key: "identifier",
    type: "string",
    label: "Identifier"
  },
  {
    key: "description",
    type: "string",
    label: "Description"
  },
  {
    key: "via",
    type: "string",
    label: "Via"
  },
  {
    key: "event",
    type: "string",
    label: "Event ID",
    params: {
      disabled: true,
    },
  },
  {
    key: "boats",
    type: "boats",
    label: "Boats",
    elements: {
      type: "boat"
    }
  },
  {
    key: "max_per_boat",
    type: "number",
    label: "Maximum amount of passengers in one boat"
  },
]
