import axios from "axios";
import { useState, useEffect, useRef } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { renderInputField } from '../utils/Inputs'
import { getEvent } from '../utils/events';
import { getBoats, getBoatsFromLeg } from "../utils/boat";
import { useParams } from "react-router-dom";
import { legFields } from "./fields";
import LinearProgress from '@mui/material/LinearProgress';

export default function AddLeg() {
    const eid = useParams().eid
    const id = useParams().id
    const [err, setErr] = useState("")
    const [msg, setMsg] = useState("")
    const [wait, setWait] = useState('')
    const [event, setEvent] = useState("")
    const [boats, setBoats] = useState([])
    const [leg, setLeg] = useState({})
    const [filteredBoats, setFilteredBoats] = useState([])
    const [boatValue, setBoatValue] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (id) {
            setLoading(true)
            let data
            axios.get('/api/leg/' + id)
                .then(r => {
                    data = r.data.leg
                    data.boats = data.boats.map(b => b.id)
                    setLeg(data)
                    getBoatsFromLeg(data.event.id, data.start_time, data.end_time, data.date, data.id)
                        .then(bts => setBoats(bts))
                    getEvent(data.event.id).then(e => {
                        setEvent(e)
                        setLeg({ ...data, event: e.id })
                        console.log()
                    })
                })
                .finally(setLeg(data))
        }
        if (eid) {
            getBoatsFromLeg(eid)
                .then(bts => setBoats(bts))
            getEvent(eid).then(e => {
                setEvent(e)
                console.log(e)
                setLeg({ ...leg, event: e.id })
            })
        }
    }, [])

    useEffect(() => {
        if (event && leg && boats.length > 0) setLoading(false)
    }, [event, leg, boats])

    useEffect(() => {
        if (leg?.start_time && leg?.end_time && leg?.date && event.id) {
            getBoatsFromLeg(event.id, leg.start_time, leg.end_time, leg.date, leg.id)
                .then(bts => setBoats(bts))
        }
    }, [leg?.start_time, leg?.end_time, leg?.date, leg?.id, event])

    const submit = (e) => {
        e.preventDefault()
        setWait(" wait")
        setErr('')
        let url = '/api/leg'
        if (id) url += '/edit'
        axios.post(url, leg)
            .then(r => {
                let m = 'Leg created'
                if (id) m = 'Leg updated'
                setMsg(m)
            })
            .catch(e => {
                let m = e.response.data.message
                if (e.response.data.missingElements)
                    m += ' ' + e.response.data.missingElements.toString()
                setErr(m)
            })
            .finally(setWait(''))
    }

    const update = (key, value) => {
        const o = { ...leg }
        o[key] = value
        setLeg(o)
    }

    const filterBoats = v => {
        setBoatValue(v)
        const filtered = boats.filter(b => {
            const ss = b.make + ' ' + b.model
            if (leg?.boats?.includes(b.id)) return false
            if (ss.toLowerCase().includes(v)) return true
            return false
        })
        if (filtered.length < 10 && v.length > 0) {
            setFilteredBoats(filtered)
        } else {
            setFilteredBoats([])
        }
    }

    const selectBoat = id => {
        const o = { ...leg }
        if (!o.boats) o.boats = []
        o['boats'].push(id)
        setLeg(o)
        setBoatValue('')
        setFilteredBoats([])
    }

    const renderBoatInput = (i, value) => {

        return (
            <div className='boatInput'>
                <input type="text" id='boats' value={value} onChange={(e) => filterBoats(e.target.value)} />
                <br />
                {filteredBoats.map((b) => {
                    return (
                        <div className="boatInput boat" onClick={() => selectBoat(b.id)} key={b.id}>
                            <span>{b.make}, {b.model} ({b?.owner?.email || 'owner deleted'})</span>
                        </div>
                    )
                })}
            </div>
        )
    }

    const removeBoat = (e, b) => {
        e.preventDefault()
        const o = { ...leg }
        const ind = o.boats.indexOf(b)
        o.boats.splice(ind, 1)
        setLeg(o)
    }

    const renderSelectedBoats = () => {
        return leg?.boats?.map(b => {
            const found = boats.find(x => x.id === b)
            return (
                <div className='selectedBoat fieldset' key={'s' + b}>
                    <b>Selected:</b> {found.make} {found.model} ({found?.owner?.email || 'owner deleted'}) <button className='smallButton deleteButton' onClick={(e) => removeBoat(e, b)}>Remove</button>
                </div>
            )
        })
    }

    const handleKey = e => {
        if (e.keyCode === 13) e.preventDefault()
    }

    const renderForm = () => {
        return (
            <form onSubmit={submit} onKeyDown={handleKey}>
                {legFields.map((f, k) => {
                    const title = f?.params?.disabled ? "You can not modify this value" : f.label
                    return (
                        <div className={'fieldset'} key={k}>
                            <label htmlFor={f.key} title={title}>{f.label}</label>
                            {f.type !== 'boats' ? renderInputField({ ...f }, leg[f.key], update) : renderBoatInput({ ...f }, boatValue)}
                        </div>
                    )
                })}
                <br />
                <p className="fieldset">Removing a boat will also delete all registrations fot that boat for this leg.</p>
                {renderSelectedBoats()}
                <div className='buttonFieldset'>
                    <button type='submit' className={'button submitButton' + wait}>
                        {wait ?
                            <CircularProgress color="grey" size="1rem" />
                            : 'Submit'}
                    </button>
                </div>
            </form>
        )
    }

    return (
        <div>
            {loading &&
                <LinearProgress />
            }
            {!loading &&
                <>
                    <h1>Add Leg for event {event.name}</h1>
                    {renderForm()}
                </>
            }
            {msg &&
                <div className='buttonFieldset'>
                    <b>{msg}</b>
                </div>
            }
            {err &&
                <p className="Error">{err}</p>
            }
        </div>
    )
}
