import { useState, useEffect } from 'react'
import { getEvents } from '../utils/events'
import LinearProgress from '@mui/material/LinearProgress';

export default function SelectEvent() {
    const [events, setEvents] = useState([])
    const [event, setEvent] = useState()

    useEffect(() => {
        getEvents(true).then(r => {
            if (r.length === 1) window.location.pathname = '/register/' + r[0].id
            setEvents(r)
        })
    }, [])

    const goTo = (id) => {
        window.location.pathname = '/register/' + id
    }


    return (
        <div className='narrow'>
            <h1>Select event</h1>
            <div className='fieldset'>
                {events.map(e => {
                    return (
                        <div className='selectEvent' onClick={() => goTo(e.id)}>{e.name}</div>
                    )
                })}
            </div>
        </div>
    )
}
