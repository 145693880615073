import './App.css';
import './style/Nav.css'
import './style/Forms.css'
import './style/Tables.css'
import './style/Cal.css'
import Nav from './components/Nav'
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Nav login={false} config={null} />
      <div className="MainContent">
        <div className='ContentWrapper'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;
