import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { getType } from "./utils/type";
import logo from "./Flotari_logo.png";

const Nav = ({ config }) => {
  const [open, setOpen] = useState("");
  const [show, setShow] = useState("nope");

  // Logged in links
  const inLinks = [
    {
      to: "/",
      text: "Home",
      tester: true,
      manufacturer:true,
    },
    {
      to: "/organizations/" + getType()?.id,
      text: "My organization",
      tester: true,
      manufacturer: true,
    },
    {
      to: "/register",
      text: "Register",
      tester: true,
      manufacturer: true,
    },
    {
      to: "/boats",
      text: "Boats",
      manufacturer: true,
    },
    {
      to: "/boats/new",
      text: "Add boats",
      tester: false,
      manufacturer: true,
    },
    {
      to: "/organizations",
      text: "Organizations",
      admin: true,
    },
    {
      to: "/events",
      text: "Events",
      admin: true,
    },
    {
      to: "/settings",
      text: "Settings",
      admin: true,
    },
    {
      to: "/downloads",
      text: "Downloads",
      tester: true,
      manufacturer: true,
    },
  ];

  const login = localStorage.getItem("token") ? true : false;

  const displayNav = () => {
    if (show === "nope") {
      setOpen("open");
      setShow("displayThis");
    } else {
      setOpen("");
      setShow("nope");
    }
  };

  const renderNavLink = (to, text) => {
    return (
      <NavLink
        key={to}
        to={to}
        className={({ isActive }) =>
          isActive ? "navbarElement active" : "navbarElement"
        }
        onClick={displayNav}
      >
        {text}
      </NavLink>
    );
  };

  const logout = () => {
    localStorage.removeItem("token");
    window.location = "/";
  };

  const navlinks = inLinks.map((link) => {
    const t = getType()?.type || null;
    if (link.admin && t !== "admin") return;
    if (!link.tester && t === "test driver") return;
    if (!link.manufacturer && t === "manufacturer") return;
    return renderNavLink(link.to, link.text);
  });

  return (
    <div className="navbar">
      <div className="navbarLeft">
        <NavLink to="/">
          <span className="logo">
            <img src={logo} alt="logo" className="navimage" />
          </span>
        </NavLink>
      </div>

      <div className="mobileNav">
        <span className="mobileNavButton" onClick={displayNav}>
          <div id="nav-icon4" className={open}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </span>
      </div>
      <div className={"navbarRight " + show}>
        {login && (
          <div>
            {navlinks}
            <p
              className="navbarElement"
              style={{ cursor: "pointer" }}
              onClick={logout}
            >
              Log out
            </p>
          </div>
        )}
        {!login && (
          <div>
            {renderNavLink("/register", "Register")}
            {renderNavLink("/login", "Log in")}
          </div>
        )}
      </div>
    </div>
  );
};

export default Nav;
