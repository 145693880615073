import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [otherError, setOtherError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const login = (event) => {
    setDisabled(true);
    event.preventDefault();
    const obj = {
      email,
    };
    axios
      .post("/api/org/login", obj)
      .then((response) => {
        console.log(response);
        if (response.data.success === true) setShowMessage(true);
      })
      .catch((error) => {
        console.log(error?.response?.data?.error);
        if (error?.response?.data?.error?.toLowerCase() === "invalid email") {
          setEmailError(true);
        } else if (
          error?.response?.data?.type?.toLowerCase() === "bad request"
        ) {
          setErrorText(error?.response?.data?.error);
        } else {
          setOtherError(true);
          setDisabled(false);
        }
      });
  };

  useEffect(() => {
    setDisabled(!email || email.trim() === "");
  }, [email, setDisabled]);

  return (
    <div>
      {!showMessage && !emailError && (
        <form className="Content" onSubmit={login}>
          <h1>Log in</h1>
          <div className="loginFieldset">
            <label htmlFor="loginEmail" className="loginLabel">
              Email address
            </label>
            <input
              type="text"
              name="loginEmail"
              className="loginInput"
              placeholder="example@example.com"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="loginButtonFieldset loginFieldset">
            <input
              type="submit"
              value="Log in"
              className="loginButton"
              disabled={disabled}
            />
          </div>
          {otherError && (
            <p className="Error">
              Something went wrong while trying to send the login link. Please
              try again after a while.{" "}
            </p>
          )}
          {errorText && <p className="Error">{errorText}</p>}
        </form>
      )}
      {showMessage && (
        <div className="Content">
          <h1>A login link has been sent</h1>
          <p>
            We have sent you an email containing a login link. To login, please
            open the link.
          </p>
        </div>
      )}
      {emailError && (
        <div className="Error">
          <h1>Email not found</h1>
          <p>
            Your email was not found in the system. If you think this is an
            error, please contact:
          </p>
        </div>
      )}
    </div>
  );
};

export default Login;
