import { useParams, Link } from "react-router-dom";
import { getType } from "../utils/type";
import { getEvents } from "../utils/events";
import { useState, useEffect } from 'react'
import axios from "axios";

export default function Download() {
    const [events, setEvents] = useState([])
    const [event, setEvent] = useState('')
    useEffect(() => {
        getEvents(true).then(e => {
            setEvents(e)
            setEvent(e[0].id)
        })
    }, [])

    const download = type => {
        axios({
            url: '/api/event/report/' + type + '/' + event, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', type + '.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    return (
        <div className='narrow'>
            <h1>Download a report</h1>
            <div className='fieldset'>
                <label htmlFor="event_selection">Select event</label>
                <select onChange={(e) => setEvent(e.target.value)} value={event}>
                    {events.map(e => {
                        return (
                            <option key={e.id} value={e.id}>{e.name}</option>
                        )
                    })}
                </select>
            </div>
            {getType().type === 'admin' && event &&
                <div>
                    <button className="button submitButton" onClick={() => download('boats')} >Download boats</button>
                    <button className="button submitButton" onClick={() => download('leg')} >Download legs</button>
                    <button className="button submitButton" onClick={() => download('bregs')} >Download registrations</button>
                    <button className="button submitButton" onClick={() => download('tester')} >Download test drivers</button>
                    <button className="button submitButton" onClick={() => download('testerdump')} >Download registered test drivers</button>
                </div>
            }
            {getType().type === 'manufacturer' && event &&
                <div>
                    <button className="button submitButton wait" onClick={() => download('boats')}>Download my boats</button>
                    <button className="button submitButton wait" onClick={() => download('myBoats')}>Download boats with registrations</button>
                    <button className="button submitButton" onClick={() => download('bregs')} >Download registrations</button>
                </div>
            }
            {getType().type === 'test driver' && event &&
                <div>
                    <button className="button submitButton wait" onClick={() => download('regs')}>Download my registrations</button>
                </div>
            }
        </div>
    )

}
