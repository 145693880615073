import { useState, useEffect } from "react";
import axios from "axios";
import { settingsFields } from "./fields";
import { renderInputField } from "../utils/Inputs";
import CircularProgress from "@mui/material/CircularProgress";

export default function Settings() {
  const [settings, setSettings] = useState({});
  const [err, setErr] = useState("");
  const [msg, setMsg] = useState("");
  const [wait, setWait] = useState("");

  useEffect(() => {
    axios
      .get("/api/settings")
      .then((r) => {
        setSettings(r.data.settings);
      })
      .catch((e) => {
        console.error(e);
        setErr(e.response.data.error);
      });
  }, []);

  const update = (key, value) => {
    const s = { ...settings };
    s[key] = value;
    setSettings(s);
  };

  const submit = (e) => {
    e.preventDefault();
    setWait(" wait");
    const s = { ...settings };
    if (s._id) {
      s.id = s._id;
      delete s._id;
    }
    if (s.__v) delete s.__v;
    axios
      .post("/api/settings", s)
      .then((r) => {
        console.log(r);
        setWait("");
        setMsg("Information updated succesfully");
      })
      .catch((e) => {
        setWait("");
        setErr(e.response.data.message);
      });
  };

  return (
    <div>
      <h1>Settings</h1>
      <form onSubmit={submit}>
        {settingsFields.map((f, k) => {
          return (
            <div className="fieldset" key={k}>
              <label htmlFor={f.key}>{f.label}</label>
              {renderInputField(f, settings[f.key], update)}
            </div>
          );
        })}
        <div className="buttonFieldset">
          <button type="submit" className={"button submitButton" + wait}>
            {wait ? <CircularProgress color="grey" size="1rem" /> : "Update"}
          </button>
        </div>
      </form>
      {msg && (
        <div>
          <b>{msg}</b>
        </div>
      )}
      {err && <p className="Error">{err}</p>}
    </div>
  );
}
